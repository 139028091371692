import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types"; //comment in if you add Proptypes to your Component


const DropDown = ({label, children, ...args}) => {

    /**
     * STATES *
     */
    const [state, setState] = useState(false);
    const [id] = useState(Date.now());

    const stateRef = useRef(state)


    /**
     * VARIABLES *
     */
    const styles = {
        display: 'block',
        position: 'absolute',
        inset: '0px 0px auto auto',
        margin: 0,
        transform: 'translate(0px, 36px)'
    }

    /**
     * HOOKS *
     */

    useEffect(() => {
        document.addEventListener('click', function(event) {
            if(stateRef.current && !event.target.classList.contains(`drop-down-${id}`)) {
                close(event);
            }
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    /**
     * FUNCTIONS *
     */
    const open = (e) => {
        e.preventDefault();

        setState(!state);
        stateRef.current = !state

        return false;
    }

    const close = (e) => {
        if(stateRef.current) {
            setState(false);
        }
    }

    return <div className="btn-group">
        <button type="button" onClick={open} className={[`drop-down-${id}`, 'btn', 'btn-success' ].concat(state ? ['show'] : []).join(' ')} {...args}>{label}</button>
        <button type="button" onClick={open} className={`drop-down-${id} btn btn-success dropdown-toggle dropdown-toggle-split`} data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" />
        <div className={['dropdown-menu' ].join(' ')} style={state ? styles : {}}>
            {children}
        </div>
    </div>
}

DropDown.propTypes = {
    label: PropTypes.string.isRequired
};

DropDown.defaultProps = {};

export default DropDown;