import {useAuth} from "../hooks/useAuth";

const IsSuperadmin = ({ children }) => {
    const { user } = useAuth()

    if (user.role === 'SUPER_ADMIN') {
        return children;
    }

};

export default IsSuperadmin;
