import React, {useEffect, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import {useLocation} from "react-router-dom";
import ApiKeyCard from "./ApiKeyCard";
import OrganizationService from "../../services/OrganizationService";

const ApiKeyCards = () => {
    const [loading, setLoading] = useState(true);
    const [apiKeyOrganisations, setApiKeyOrganisations] = useState([]);
    let location = useLocation();
    const { user } = useAuth();

    const fetchApiKeyOrganisations = async () => {
        const response = await OrganizationService.getApiKeyOrganizations(user);
        setApiKeyOrganisations(response.data);
        setLoading(false);
    };

    console.log(apiKeyOrganisations);

    useEffect(() => {
        fetchApiKeyOrganisations();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='row'>
            {
                loading ? (
                    <Loader card={false}/>
                ) : (
                    apiKeyOrganisations.map((organisation, index) => (
                        organisation.apiKeys.map((item, index) => (
                            <ApiKeyCard key={index} organisation={organisation} item={item} />
                        ))
                    ))
                )
            }
        </div>
    );
};

export default ApiKeyCards;
