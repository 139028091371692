import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import ApiKeyCards from "./ApiKeyCards";

const ApiKeys = () => {
    const {user} = useAuth()

    return (
        <div>
            { user.role === 'SUPER_ADMIN' && (
                <ApiKeyCards />
            )}
            { user.role !== 'SUPER_ADMIN' && (
                <span>
                    Du hast keine Brechtigung hier zu sein.
                </span>
            )}

        </div>
    );
};

export default ApiKeys;