import React from 'react';
import SidebarLogo from './SidebarLogo';
import {
    RiDashboard2Line,
    RiBuildingLine,
    RiCalendarLine,
    RiTableLine,
    RiDragDropLine,
    RiAccountCircleLine, RiKey2Line,
} from 'react-icons/ri';

import SimpleBar from 'simplebar-react';
import SidebarTitle from './SidebarTitle';
import NavLink from "./NavLink";
import IsSuperadmin from "../components/IsSuperadmin";


const Sidebar = ({...props}) => {

    return (
        <div className='app-menu navbar-menu'>
            <SidebarLogo />
            <ul className='navbar-nav'>
                <SimpleBar className='navbar-nav'>
                    <SidebarTitle>Menu</SidebarTitle>
                    <NavLink icon={<RiDashboard2Line />} to='/organisationen' title='Dashboard' />
                    <NavLink icon={<RiAccountCircleLine />} to='/profil' title='Profil' />
                    <NavLink icon={<RiBuildingLine />} to='/management' title='Objektstruktur' />
                    <NavLink icon={<RiCalendarLine />} to='/saison-wizard' title='Saisonkalendar' />
                    <NavLink icon={<RiDragDropLine />} to='/objects-and-seasons' title='Objekte und Saisons' />
                    <NavLink icon={<RiTableLine />} to='/olap' title='Eigenschaften' />
                    <IsSuperadmin>
                        <NavLink icon={<RiKey2Line />} to='/apikeys' title='ApiKeys' />
                    </IsSuperadmin>
                </SimpleBar>
            </ul>

            <div className='sidebar-background' />
        </div>
    );
};

export default Sidebar;
