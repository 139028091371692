import React from 'react';
import Footer from '../parts/Footer';
import MainContent from '../parts/MainContent';
import Sidebar from '../parts/Sidebar';
import TopBar from '../parts/TopBar';
import { Routes, Route } from 'react-router-dom';
import Profile from '../modules/Profile/Profile';
import Invitation from '../modules/Invitation/Invitation';
import Organisations from '../modules/Organisations/Organisations';
import { ProvideOrganization } from '../hooks/useOrganization';
import HasOganization from '../components/HasOrganization';
import PlaceManagement from '../modules/ObjectManagement/PlaceManagement/PlaceManagement';
import CategoryManagement from '../modules/ObjectManagement/CategoryManagement/CategoryManagement';
import SaisonWizard from "../modules/SaisonWizard/SaisonWizard";
import OlapManagement from "../modules/OLAP/Management/OlapManagement";
import Olap from "../modules/OLAP/Olap";
import ObjectsAndSeasons from "../modules/ObjectsAndSeasons/ObjectsAndSeasons";
import CategoryProperties from "../modules/OLAP/CategoryProperties/CategoryProperties";
import CategorySeasonProperties from "../modules/OLAP/CategorySeasonProperties/CategorySeasonProperties";
import ObjectProperties from "../modules/OLAP/ObjectProperties/ObjectProperties";
import ObjectSeasonProperties from "../modules/OLAP/ObjectSeasonProperties/ObjectSeasonProperties";
import PlaceProperties from "../modules/OLAP/PlaceProperties/PlaceProperties";
import ApiKeys from "../modules/ApiKeys/ApiKeys";

// ToDo lazy loading

const Dashboard = () => {
    return (
        <ProvideOrganization>
            <div id='layout-wrapper'>
                <TopBar />
                <Sidebar />
                <MainContent>
                    <Routes>
                        <Route
                            path='/management'
                            element={
                                <HasOganization>
                                    <PlaceManagement />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/management/place/:placeId'
                            element={
                                <HasOganization>
                                    <CategoryManagement />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/management/place/:placeId/category/:categoryId'
                            element={
                                <HasOganization>
                                    <CategoryManagement />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/profil'
                            element={
                                <HasOganization>
                                    <Profile />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/saison-wizard'
                            element={
                                <HasOganization>
                                    <SaisonWizard />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/saison-wizard/calendar/:calendarId'
                            element={
                                <HasOganization>
                                    <SaisonWizard />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/'
                            element={
                                <HasOganization>
                                    <Olap />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/testtabellen/'
                            element={
                                <HasOganization>
                                    <OlapManagement />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/place/:placeId/category/:categoryId/'
                            element={
                                <HasOganization>
                                    <CategoryProperties />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/place/:placeId/category/:categoryId/object/:objectId/'
                            element={
                                <HasOganization>
                                    <ObjectProperties />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/place/:placeId/'
                            element={
                                <HasOganization>
                                    <PlaceProperties />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/place/:placeId/category/:categoryId/season'
                            element={
                                <HasOganization>
                                    <CategorySeasonProperties />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/olap/place/:placeId/category/:categoryId/object/:objectId/season'
                            element={
                                <HasOganization>
                                    <ObjectSeasonProperties />
                                </HasOganization>
                            }
                        />
                        <Route
                            path='/objects-and-seasons'
                            element={
                                <HasOganization>
                                    <ObjectsAndSeasons />
                                </HasOganization>
                            }
                        />
                        <Route path='/einladung' element={<Invitation />} />
                        <Route path='/organisationen' element={<Organisations />} />
                        <Route path='/apikeys' element={<ApiKeys />} />
                    </Routes>
                    <Footer />
                </MainContent>
            </div>
        </ProvideOrganization>
    );
};

export default Dashboard;
