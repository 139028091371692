import React from 'react';

const ApiKeyCard = ({organisation, item}) => {
    return (
        <div className='col-xxl-3 col-sm-6'>
            <div>
                <div className='card camp-profile-project-card shadow-none'>
                    <div className='card-body p-4'>
                        <div className='d-flex align-items-center'>
                            <div className='flex-grow-1 overflow-hidden'>
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    {organisation.organization.name}
                                </p>
                            </div>
                        </div>
                        <div className='d-flex align-items-end justify-content-between mt-4'>
                            <h4 className='fs-14 fw-semibold ff-secondary mb-4'>
                                {item.apiKey.apiKey}
                            </h4>
                        </div>
                        <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-muted'>
                                {
                                    item.places.map((place, index) => (
                                        <span key={index} className='badge bg-light text-dark me-1'>
                                            {place.name}
                                        </span>
                                    ))
                                }
                                {
                                    item.places.length === 0 && (
                                        <span className='badge bg-light text-dark me-1'>
                                            Keine Plätze hinterlegt
                                        </span>
                                    )
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApiKeyCard;
