import React, {Fragment, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {useOrganization} from "../../hooks/useOrganization";
import serviceService from "../../services/ServiceService";
import OlapTooltip from "./OlapTooltip";
import {isEmpty} from "../../utils/validation";
import DropDown from "../../components/DropDown";
import {BiSolidDownArrow, BiSolidRightArrow, BiSolidUpArrow} from "react-icons/bi";
import {RiArrowDownSLine, RiCloseLine} from "react-icons/ri";

const AddServiceModal = ({services, onSubmit}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const { user } = useAuth();
    const {organization} = useOrganization();

    const [collapseField, setCollapseField] = useState(null);
    const [customFieldsCache, setCustomFieldsCache] = useState([]);
    const [state, setState] = useState({
        overrides: 0,
        name: [
            {
                locale: "de",
                text: ""
            }
        ],
        description: [
            {
                locale: "de",
                text: ""
            }
        ],
        paymentInterval: "PER_DAY",
        serviceCalculationType: "PER_PERSON",
        price: 0,
        maximumBookable: 0,
        ageMin: '',
        ageMax: '',
        nightMin: '',
        nightMax: '',
        addedAutomatically: false,
        inclusive: true,
        hidden: false,
        onSite: false,
        freeOfCharge: false,
        ignoreWhenCalculatingTaxes: false,
        tax: 0,
        customerType: "BOTH",
        customizableType: "NONE",
        customFields: []
    });

    const customFieldDropDownValues = [
        {key: 'FIRSTNAME', label: 'Vorname'},
        {key: 'LASTNAME', label: 'Nachname'},
        {key: 'BIRTHDATE', label: 'Geburtstag'},
        {key: 'AGE', label: 'Alter'},
        {key: 'CAR_MARK', label: 'KFZ-Kennzeichen'},
        {key: 'VEHICLE_TYPE', label: 'Fahrzeugart'},
        {key: 'VEHICLE_LENGTH', label: 'Fahrzeuglänge'},
        {key: 'PEOPLE_IS_SEVERELY_DISABLED', label: 'Schwerbehindert'},
        {key: 'PEOPLE_IS_STUDENT', label: 'Student'},
        {key: 'ASSISTANCE_DOG', label: 'Assistenzhund'},
        {key: 'EMAIL', label: 'E-Mail'},
    ]

    const clearState = () => {
        setCustomFieldsCache([]);
        setState({
            overrides: 0,
            name: [
                {
                    locale: "de",
                    text: ""
                }
            ],
            description: [
                {
                    locale: "de",
                    text: ""
                }
            ],
            paymentInterval: "PER_DAY",
            serviceCalculationType: "PER_PERSON",
            price: 0,
            maximumBookable: 0,
            ageMin: '',
            ageMax: '',
            nightMin: '',
            nightMax: '',
            addedAutomatically: false,
            inclusive: true,
            hidden: false,
            onSite: false,
            freeOfCharge: false,
            ignoreWhenCalculatingTaxes: false,
            tax: 0,
            customerType: "BOTH",
            customizableType: "NONE",
            customFields: []
        });
    }

    const getKurTaxeCustomFields = () => {
        return [
            {
                customField: 'FIRSTNAME',
                required: true,
                description: [
                    {
                        locale: "de",
                        text: ""
                    }
                ],
                allowedVehicleTypes: []
            },
            {
                customField: 'LASTNAME',
                required: true,
                description: [
                    {
                        locale: "de",
                        text: ""
                    }
                ],
                allowedVehicleTypes: []
            },
            {
                customField: 'EMAIL',
                required: true,
                description: [
                    {
                        locale: "de",
                        text: ""
                    }
                ],
                allowedVehicleTypes: []
            }
        ];
    }

    const moveCustomField = (type, move) => {
        let customFields = !!state.customFields ? state.customFields : [];
        const index = customFields.findIndex(item => item.customField === type);

        if(index !== -1) {
            const item = customFields[index];
            customFields.splice(index, 1);
            customFields.splice(index + move, 0, item);

            customFields = customFields.map((item, index) => {
                item.position = index;

                return item
            })

            setState({...state, ...{customFields: customFields}})
        }
    }

    const deleteCustomField = (type) => {
        let customFields = !!state.customFields ? state.customFields : [];
        const index = customFields.findIndex(item => item.customField === type);

        if(index !== -1) {
            customFields.splice(index, 1);
            setState({...state, ...{customFields: customFields}})
        }
    }

    const removeFromList = (list, value) => {
        const index = list.findIndex(item => item === value);

        if(index !== -1) {
            list.splice(index, 1);
        }

        return list
    }

    const collapse = (type) => {
        if(collapseField === type) {
            setCollapseField(null)
        } else {
            setCollapseField(type);
        }

    }

    const updateCustomField = (type, args, remove) => {
        let customFields = !!state.customFields ? state.customFields : [];
        const index = customFields.findIndex(item => item.customField === type);

        if(index !== -1) {
            if(!!args) {
                customFields[index] = {...customFields[index], ...args}
            }
        } else {
            customFields.push({
                customField: type,
                required: false,
                order: customFields.length,
                description: [
                    {
                        locale: "de",
                        text: ""
                    }
                ],
                allowedVehicleTypes: type === 'VEHICLE_TYPE' ? ['CAR', 'CARAVAN', 'MOTOR_HOME', 'CAMPERVAN', 'OTHER'] : []
            });
            collapse(type);
        }

        setState({...state, ...{customFields: customFields}})
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        clearState()
    };

    const submitModal = () => {
        if(validate()){
            setIsModalOpen(false);
            saveChanges();
        }
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};

        // Validate minAge and maxAge minAge must be less than maxAge
        if (state.ageMin !== '' && state.ageMax !== '') {
            if (state.ageMin >= state.ageMax) {
                errors['ageMin'] = 'Mindestalter muss kleiner als Höchstalter sein';
                hasErrors = true;
            }
        }

        //validate customizableType if is kurtaxe then automatically need to be true
        if (state.customizableType === 'TOURIST_TAX' && !state.addedAutomatically) {
            errors['kurtaxe'] = 'Kurtaxe kann nur ausgewählt werden, wenn die Leistung automatisch gewählt wird.';
            hasErrors = true;
        }

        //validate customizableType if is kurtaxe then hidden need to be false
        if (state.customizableType === 'TOURIST_TAX' && state.hidden) {
            errors['kurtaxeHidden'] = 'Kurtaxe kann nicht ausgewählt werden, wenn die Leistung ausgeblendet wird.';
            hasErrors = true;
        }

        if (state.customizableType === 'CAR' && state.hidden) {
            errors['carHidden'] = 'KFZ-Kennzeichen kann nicht ausgewählt werden, wenn die Leistung ausgeblendet wird.';
            hasErrors = true;
        }

        //validate name can not be empty
        if (isEmpty(state.name[0].text)) {
            errors['name'] = 'Name darf nicht leer sein';
            hasErrors = true;
        }

        setErrors(errors);

        return !hasErrors;

    };



    const saveChanges = async () => {
        state.name[0].text = state.name[0].text.trim();
        state.description[0].text = state.description[0].text.trim();
        try {
            await serviceService.postOrganizationServices(organization.id, user, state);
            onSubmit();
            clearState();
        } catch (err) {
            throw err;
        }
    };

    const change = (e) => {
        const { name, value, type, checked } = e.target;

        setState((prevState) => {
            if (type === 'checkbox') {
                if (name === 'customerType') {
                    const updatedCustomerType = checked ? 'PRIVATE' : 'BOTH';

                    return {
                        ...prevState,
                        customerType: updatedCustomerType,
                    };
                }  else if(name === 'customizableType') {
                    let customFields = [];
                    if(checked) {
                        setCustomFieldsCache(prevState.customFields)
                        customFields = getKurTaxeCustomFields();
                    } else {
                        customFields = customFieldsCache;
                        setCustomFieldsCache([])
                    }

                    return {
                        ...prevState,
                        addedAutomatically: true,
                        customizableType: checked ? value : 'NONE',
                        customFields
                    };
                } else if (name === 'addedAutomatically' && checked) {
                    return {
                        ...prevState,
                        addedAutomatically: true,
                        inclusive: false,
                    };
                } else if (name === 'inclusive' && checked) {
                    return {
                        ...prevState,
                        addedAutomatically: false,
                        inclusive: true,
                        hidden: false,
                    };
                } else if (name === 'hidden' && checked) {
                    let customFields = [];
                    setCustomFieldsCache(prevState.customFields)

                    return {
                        ...prevState,
                        addedAutomatically: true,
                        inclusive: false,
                        hidden: true,
                        customFields
                    };
                } else {
                    const update = {[name]: checked,}
                    if(['onSite', 'hidden'].indexOf(name) !== -1) {
                        let customFields = [];
                        if(checked) {
                            setCustomFieldsCache(prevState.customFields)
                        } else {
                            customFields = customFieldsCache;
                            setCustomFieldsCache([])
                        }

                        update.customFields = customFields;
                    }

                    return {
                        ...prevState,
                        ...update
                    };
                }
            } else if (name === 'name' || name === 'description') {
                return {
                    ...prevState,
                    [name]: [
                        {
                            locale: 'de',
                            text: value,
                        },
                    ],
                };
            } else if (name === 'price') {
                if (value.includes('.')){
                    const split = value.split('.');
                    if (split[1].length > 2) {
                        return {
                            ...prevState
                        };
                    }
                }
                if (value.includes(',')){
                    const split = value.split(',');
                    if (split[1].length > 2) {
                        return {
                            ...prevState
                        };
                    }
                }
                return {
                    ...prevState,
                    [name]: value,
                };
            } else if (name === "ageMin" || name === "ageMax" || name === "maximumBookable"){
                //only positive full numbers
                if (!isNaN(value) && value >= 0 && value % 1 === 0) {
                    return {
                        ...prevState,
                        [name]: value,
                    };
                } else {
                    return {
                        ...prevState
                    };
                }

            } else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }

        });
    };

    const validatePriceOnBlur = (e) => {
        const {name, value} = e.target;
        setState((prevState) => {
            if (name === 'price') {
                if (value === '') {
                    return {
                        ...prevState,
                        price: 0,
                    };
                } else{
                    return {
                        ...prevState
                    };

                }
            }
        });
    }

    const validateNumberFieldOnBlur = (e) => {
        const {name, value} = e.target;
        //maximum 150 if over 150 then 150
        setState((prevState) => {
            if (value === '') {
                return {
                    ...prevState,
                    [name]: '',
                };
            } else if (value > 150) {
                return {
                    ...prevState,
                    [name]: 150,
                };
            } else {
                return {
                    ...prevState
                };
            }
        });
    }

    return (
        <div style={{zIndex: "1051"}}>
            <div onClick={openModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                Leistungsvorlage hinzufügen +
            </div>
            <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isModalOpen ? 'show' : ''}`}
                 style={{ display: isModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Neue Leistungsvorlage hinzufügen
                            </h5>
                            <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='name' className='form-label'>
                                            Name der Leistung*
                                            <OlapTooltip
                                                Text={'Der Name der Leistung wird in der Buchungsmaske angezeigt.'}/>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control`}
                                            name='name'
                                            value={state.name[0].text}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                {errors.name && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.name}</div></div>}
                                <div className='col-md-12'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='description' className='form-label'>
                                            Beschreibung
                                            <OlapTooltip
                                                Text={'Der Hinweis, der dem Nutzer für diese Leistung angezeigt wird.'}/>
                                        </label>
                                        <textarea
                                            className='form-control'
                                            name='description'
                                            value={state.description[0].text}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='price' className='form-label'>
                                            Preis*
                                            <OlapTooltip
                                                Text={'Der Preis der Leistung des Objektes oder der Objektkategorie, in einer Saison oder einem bestimmten Tag'}/>
                                        </label>
                                        <input
                                            type='number'
                                            step='0.01'
                                            className={`form-control`}
                                            name='price'
                                            value={state.price}
                                            onChange={change}
                                            onBlur={validatePriceOnBlur}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='paymentInterval' className='form-label'>
                                            Berechnungsintervall*
                                            <OlapTooltip
                                                Text={'Berechnungsintervall, Berechnung erfolgt pro Zeiteinheit'}/>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='paymentInterval'
                                            value={state.paymentInterval}
                                            onChange={change}
                                        >
                                            <option value='PER_BOOKING'>pro Buchung</option>
                                            <option value='PER_DAY'>pro Tag</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='serviceCalculationType' className='form-label'>
                                            Berechnungseinheit*
                                            <OlapTooltip Text={'Berechnung erfolgt pro Berechnungseinheit'}/>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='serviceCalculationType'
                                            value={state.serviceCalculationType}
                                            onChange={change}
                                        >
                                            <option value='PER_BOOKING'>pro Buchung</option>
                                            <option value='PER_OBJECT'>pro Objekt</option>
                                            <option value='PER_PERSON'>pro Person</option>
                                            <option value='PER_PERSON_OVER_STANDARD'>pro Person über Standard</option>
                                            <option value='PER_PERSON_BELOW_STANDARD'>pro Person unter Standard</option>
                                            <option value='ONLY_IF_PET '>Nur wenn Tiere</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='maximumBookable' className='form-label'>
                                            Maximale Anzahl
                                            <OlapTooltip
                                                Text={'Optionale Angabe zur maximal verfügbaren Anzahl dieser Leistung'}/>
                                        </label>
                                        <input
                                            type='number'
                                            className={`form-control`}
                                            name='maximumBookable'
                                            value={state.maximumBookable}
                                            onChange={change}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='ageMin' className='form-label'>
                                            Alter von
                                            <OlapTooltip
                                                Text={'Optionale Angabe, ab welchem Gastalter diese Leistung anwendbar ist z. B. "18" für Leistungen für Erwachsene'}/>
                                        </label>
                                        <input
                                            type='number'
                                            className={`form-control`}
                                            name='ageMin'
                                            value={state.ageMin}
                                            onChange={change}
                                            onBlur={validateNumberFieldOnBlur}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='ageMax' className='form-label'>
                                            Alter bis
                                            <OlapTooltip
                                                Text={'Optionale Angabe, bis zu welchem Gastalter diese Leistung anwendbar ist z. B. "3" für Leistungen fürBabys'}/>
                                        </label>
                                        <input
                                            type='number'
                                            className={`form-control`}
                                            name='ageMax'
                                            value={state.ageMax}
                                            onChange={change}
                                            onBlur={validateNumberFieldOnBlur}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='nightMin' className='form-label'>
                                            N&auml;chte von
                                            <OlapTooltip
                                                Text={'Ab welcher Aufenthaltsdauer wird die Leistung berechnet z. B. Ab "3" Nächte. Nützlich für Kurzreisezuschlag'}/>
                                        </label>
                                        <input
                                            type='number'
                                            className={`form-control`}
                                            id={'nightMin'}
                                            name='nightMin'
                                            value={state.nightMin}
                                            onChange={change}
                                            onBlur={validateNumberFieldOnBlur}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='nightMax' className='form-label'>
                                            N&auml;chte bis
                                            <OlapTooltip
                                                Text={'Bis zu welcher Aufenthaltsdauer wird die Leistung berechnet z. B. Bis "5" Nächte. Nützlich für Kurzreisezuschlag'}/>
                                        </label>
                                        <input
                                            type='number'
                                            id={'nightMax'}
                                            className={`form-control`}
                                            name='nightMax'
                                            value={state.nightMax}
                                            onChange={change}
                                            onBlur={validateNumberFieldOnBlur}
                                            onKeyDown={(e) => {
                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {errors.ageMin && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.ageMin}</div></div>}
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='addedAutomatically' className='form-check-label'>
                                            Automatisch gewählt
                                            <OlapTooltip
                                                Text={'Leistung wird bei jeder Buchung automatisch mit ausgewählt.'}/>
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            name='addedAutomatically'
                                            id={'addedAutomatically'}
                                            checked={state.addedAutomatically}
                                            onChange={change}
                                            disabled={state.hidden || state.onSite || state.customizableType === 'TOURIST_TAX'}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='inclusive' className='form-check-label'>
                                            Inklusive
                                            <OlapTooltip
                                                Text={'Leistung ist im Übernachtungspreis enthalten. Bedeutet auch keine Bettensteuer/keine Kurtaxe/keine Provision.'}/>
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            name='inclusive'
                                            id={'inclusive'}
                                            checked={state.inclusive}
                                            onChange={change}
                                            disabled={state.hidden || state.onSite}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='hidden' className='form-check-label'>
                                            Ausblenden
                                            <OlapTooltip
                                                Text={'Leistung wird mit in den Übernachtungspreis eingerechnet und ausgeblendet, wird also nicht als separate Position ausgegeben'}/>
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            id={'hidden'}
                                            name='hidden'
                                            checked={state.hidden}
                                            onChange={change}
                                            disabled={state.inclusive || state.onSite || state.customizableType === 'TOURIST_TAX'}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='onSite' className='form-check-label'>
                                            Nur vor Ort
                                            <OlapTooltip
                                                Text={'Leistung wird als mögliche Leistung angezeigt, kann aber online nicht gebucht werden'}/>
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            id={'onSite'}
                                            name='onSite'
                                            checked={state.onSite}
                                            onChange={change}
                                            disabled={state.inclusive || state.addedAutomatically || state.hidden || state.customizableType === 'TOURIST_TAX'}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='customizableType' className='form-check-label'>
                                            Ist Kursteuer
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            id={'customizableType'}
                                            name='customizableType'
                                            checked={state.customizableType === 'TOURIST_TAX'}
                                            value={'TOURIST_TAX'}
                                            onChange={change}
                                            disabled={state.hidden || state.onSite}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='tax' className='form-label'>
                                            Steuer
                                            <OlapTooltip Text={'Leistung ist steuerpflichtig (nur notwendig, wenn der Rechnungsersteller/Vermieter umsatzsteuerpflichtig ist)'}/>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='tax'
                                            value={state.tax}
                                            onChange={change}
                                        >
                                            <option value={0}>0%</option>
                                            <option value={7}>7%</option>
                                            <option value={19}>19%</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={'col-md-6 mb-5'}>
                                    <label htmlFor='freeOfCharge' className='form-check-label'>
                                        Gebührenfrei
                                        <OlapTooltip
                                            Text={'Leistung muss 1:1 an den Kunden weitergereicht werden. z.B. die Kurtaxe selber'}/>
                                    </label>
                                    <input
                                        type='checkbox'
                                        className='form-check-input ms-2 cursor-pointer'
                                        id={'freeOfCharge'}
                                        name='freeOfCharge'
                                        checked={state.freeOfCharge}
                                        onChange={change}
                                    />
                                </div>
                                <div className={'col-md-6 mb-5'}>
                                    <label htmlFor='ignoreWhenCalculatingTaxes' className='form-check-label'>
                                        Für Kurtaxe/Bettensteuer ignorieren
                                        <OlapTooltip
                                            Text={'Leistung wird bei der Berechnung ignoriert.'}/>
                                    </label>
                                    <input
                                        type='checkbox'
                                        className='form-check-input ms-2 cursor-pointer'
                                        id={'ignoreWhenCalculatingTaxes'}
                                        name='ignoreWhenCalculatingTaxes'
                                        checked={state.ignoreWhenCalculatingTaxes}
                                        onChange={change}
                                    />
                                </div>
                                <div className='col-md-12 d-none'>
                                    <div className={'mb-3'}>
                                        <label htmlFor='customerType' className='form-check-label'>
                                            Nicht für geschäftliche Buchungen
                                            <OlapTooltip
                                                Text={'Die Leistung wird nicht berechnet, wenn die Buchung eine geschäftliche Buchung ist.'}/>
                                        </label>
                                        <input
                                            type='checkbox'
                                            className='form-check-input ms-2 cursor-pointer'
                                            id={'customerType'}
                                            value={'PRIVATE'}
                                            name='customerType'
                                            checked={state.customerType.includes('PRIVATE')}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                <div className={`col-md-9`}>
                                    <div className={'mt-2'}>
                                        <label htmlFor='customizableType' className='form-label'>
                                            Zusätzliches Feld
                                            <OlapTooltip
                                                Text={'Hier können Sie ein zusätzliches Feld angeben, indem Ihre Kunden zum Beispiel ein KFZ Kennzeichen angeben können. Für Kurtaxe legen sie bitte ein extra Service "Kurtaxe" an. Der Preis darf nicht 0€ sein.'}/>
                                        </label>
                                    </div>
                                </div>
                                <div className={`col-md-3`}>
                                    {!state.onSite && state.customizableType !== 'TOURIST_TAX' && !state.hidden && customFieldDropDownValues.filter(search => !!state.customFields && state.customFields.findIndex(item => item.customField === search.key) === -1).length > 0 && (
                                        <div className={'mb-3'}>
                                            <DropDown label={'Feld hinzufügen'}>
                                                {customFieldDropDownValues.filter(search => !!state.customFields && state.customFields.findIndex(item => item.customField === search.key) === -1).map((item, index) => <a key={`custom-field-dropdown-item${index}`} className="dropdown-item" onClick={() => {updateCustomField(item.key)}}>{item.label}</a>)}
                                            </DropDown>
                                        </div>
                                    )}
                                </div>
                                <div className={`col-md-12`}>
                                    <div className={'mb-3'}>
                                        {state.onSite && (
                                            <div className="alert alert-warning" role="alert">
                                                Benutzerdefinierte Felder Können nicht hinzugefügt werden, wenn die Leistung nur vor Ort bezahlt werden kann.
                                            </div>
                                        )}

                                        {!state.onSite && state.customizableType === 'TOURIST_TAX' && (
                                            <div className="alert alert-warning" role="alert">
                                                Benutzerdefinierte Felder Können nicht hinzugefügt werden, wenn die Leistung eine Kurtaxe/Bettensteuer ist.
                                                Die Benutzerdefinierten Felder <strong>Vorname</strong>, <strong>Nachname</strong>, <strong>E-Mail</strong> sind automatisch hinzugef&uuml;gt.
                                            </div>
                                        )}

                                        {!state.onSite && state.customizableType !== 'TOURIST_TAX' && state.hidden && (
                                            <div className="alert alert-warning" role="alert">
                                                Benutzerdefinierte Felder Können nicht hinzugefügt werden, wenn die Leistung versteckt ist.
                                            </div>
                                        )}

                                        {!state.onSite && state.customizableType !== 'TOURIST_TAX' && !state.hidden && (!state.customFields || state.customFields.length === 0) && (
                                            <div className="alert alert-primary" role="alert">
                                                Keine Benutzerdefinierten Felder ausgewählt.
                                            </div>
                                        )}
                                        {!state.onSite && state.customizableType !== 'TOURIST_TAX' && !state.hidden && (!!state.customFields && state.customFields.length > 0) && (
                                            <table className="table table-nowrap no-hover-effect">
                                                <thead className="table-light">
                                                <tr>
                                                    <th scope="col">Feldname</th>
                                                    <th scope="col">
                                                        Ist Plichtfeld?
                                                        <OlapTooltip
                                                            Text={'Wenn aktiviert, muss der Kunde dieses Feld bei der Buchung der Leistung ausfüllen.'}/>
                                                    </th>
                                                    <th scope="col">
                                                        Nur Einmal benötigt?
                                                        <OlapTooltip
                                                            Text={'Wenn aktiviert, ist dieses Feld bei der Leistung nur einmal verfügbar auch wenn die Leistung mehrfach gebucht wird.'}/>
                                                    </th>
                                                    <th scope="col" />
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {state.customFields.map((item, itemKey) => <Fragment>
                                                    <tr className={item.customField === collapseField ? 'transparent-border' : ''}>
                                                        <th>
                                                            <RiArrowDownSLine className={'cursor-pointer'} onClick={() => {collapse(item.customField)}} style={{fontSize: 20, transform: `rotate(${item.customField === collapseField ? '0' : '-90'}deg)`}}/>
                                                            {customFieldDropDownValues.find(option => option.key === item.customField).label}
                                                        </th>
                                                        <td>
                                                            <label htmlFor={'custom-field-' + item.customField}
                                                                   className='form-check-label'>
                                                                Ja
                                                            </label>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input ms-2 cursor-pointer'
                                                                id={'custom-field-' + item.customField}
                                                                checked={item.required ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    updateCustomField(item.customField, {required: e.target.checked})
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <label htmlFor={'custom-field-' + item.customField + '-only-once'}
                                                                   className='form-check-label'>
                                                                Ja
                                                            </label>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input ms-2 cursor-pointer'
                                                                id={'custom-field-' + item.customField + '-only-once'}
                                                                checked={item.onlyOneTimeNeeded ? 'checked' : ''}
                                                                onChange={(e) => {
                                                                    updateCustomField(item.customField, {onlyOneTimeNeeded: e.target.checked})
                                                                }}
                                                                disabled={state.inclusive || state.addedAutomatically}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className={'row'}>
                                                                <div className={'col-4'}>
                                                                    <button
                                                                        disabled={itemKey === 0}
                                                                        onClick={() => {
                                                                            moveCustomField(item.customField, -1)
                                                                        }} className="btn-olap-modal btn-info btn btn-info btn-lg p-2">
                                                                        <BiSolidUpArrow />
                                                                    </button>
                                                                </div>
                                                                <div className={'col-4'}>
                                                                    <button
                                                                        disabled={itemKey === state.customFields.length - 1}
                                                                        onClick={() => {
                                                                            moveCustomField(item.customField, 1)
                                                                        }} className="btn-olap-modal btn-info btn btn-info btn-lg p-2">
                                                                        <BiSolidDownArrow />
                                                                    </button>
                                                                </div>
                                                                <div className={'col-4'}>
                                                                    <button onClick={() => {
                                                                        deleteCustomField(item.customField)
                                                                    }} className="btn-olap-modal btn-info btn btn-danger btn-lg p-2">
                                                                        <RiCloseLine />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className={`collapse-able-row ${item.customField === collapseField ? 'open' : ''}`}>
                                                        <td colSpan="5">
                                                            <div className={'row'}>
                                                                <div className={'offset-1 col-11'}>
                                                                    {item.customField === 'VEHICLE_TYPE' && (<Fragment>
                                                                        <label htmlFor='description' className='form-label'>
                                                                            Fahrzeugarten
                                                                            <OlapTooltip
                                                                                Text={'Hier können Sie definieren welche Fahrzeugart zur Auswahl steht.'}/>
                                                                        </label>
                                                                        <div className={'row mb-3'}>
                                                                            <div className={'col-2'}>
                                                                                <label htmlFor={'custom-field-car'}
                                                                                       className='form-check-label'>
                                                                                    PKW
                                                                                </label>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='form-check-input ms-2 cursor-pointer'
                                                                                    id={'custom-field-car'}
                                                                                    value={'CAR'}
                                                                                    checked={item.allowedVehicleTypes.indexOf('CAR') !== -1 ? 'checked' : ''}
                                                                                    onChange={(e) => {
                                                                                        updateCustomField(item.customField, {allowedVehicleTypes: e.target.checked ? [...item.allowedVehicleTypes, e.target.value]: removeFromList(item.allowedVehicleTypes, e.target.value)}, )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={'col-2'}>
                                                                                <label htmlFor={'custom-field-car'}
                                                                                       className='form-check-label'>
                                                                                    Caravan
                                                                                </label>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='form-check-input ms-2 cursor-pointer'
                                                                                    id={'custom-field-caravan'}
                                                                                    value={'CARAVAN'}
                                                                                    checked={item.allowedVehicleTypes.indexOf('CARAVAN') !== -1 ? 'checked' : ''}
                                                                                    onChange={(e) => {
                                                                                        updateCustomField(item.customField, {allowedVehicleTypes: e.target.checked ? [...item.allowedVehicleTypes, e.target.value]: removeFromList(item.allowedVehicleTypes, e.target.value)}, )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={'col-2'}>
                                                                                <label htmlFor={'custom-field-motor-home'}
                                                                                       className='form-check-label'>
                                                                                    Wohnmobil
                                                                                </label>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='form-check-input ms-2 cursor-pointer'
                                                                                    id={'custom-field-motor-home'}
                                                                                    value={'MOTOR_HOME'}
                                                                                    checked={item.allowedVehicleTypes.indexOf('MOTOR_HOME') !== -1 ? 'checked' : ''}
                                                                                    onChange={(e) => {
                                                                                        updateCustomField(item.customField, {allowedVehicleTypes: e.target.checked ? [...item.allowedVehicleTypes, e.target.value]: removeFromList(item.allowedVehicleTypes, e.target.value)}, )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={'col-2'}>
                                                                                <label htmlFor={'custom-field-campervan'}
                                                                                       className='form-check-label'>
                                                                                    Campervan
                                                                                </label>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='form-check-input ms-2 cursor-pointer'
                                                                                    id={'custom-field-campervan'}
                                                                                    value={'CAMPERVAN'}
                                                                                    checked={item.allowedVehicleTypes.indexOf('CAMPERVAN') !== -1 ? 'checked' : ''}
                                                                                    onChange={(e) => {
                                                                                        updateCustomField(item.customField, {allowedVehicleTypes: e.target.checked ? [...item.allowedVehicleTypes, e.target.value]: removeFromList(item.allowedVehicleTypes, e.target.value)}, )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={'col-2'}>
                                                                                <label htmlFor={'custom-field-other'}
                                                                                       className='form-check-label'>
                                                                                    Andere
                                                                                    <OlapTooltip
                                                                                        Text={'Der Kunde erhält ein Eingabefeld wo er sine Fahrzeugart eintragen kann.'}/>
                                                                                </label>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='form-check-input ms-2 cursor-pointer'
                                                                                    id={'custom-field-other'}
                                                                                    value={'OTHER'}
                                                                                    checked={item.allowedVehicleTypes.indexOf('OTHER') !== -1 ? 'checked' : ''}
                                                                                    onChange={(e) => {
                                                                                        updateCustomField(item.customField, {allowedVehicleTypes: e.target.checked ? [...item.allowedVehicleTypes, e.target.value]: removeFromList(item.allowedVehicleTypes, e.target.value)}, )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>)}
                                                                    <label htmlFor='description' className='form-label'>
                                                                        Beschreibung
                                                                        <OlapTooltip
                                                                            Text={'Hier haben Sie die Möglichkeit eine Erklärung des Feldes für den Kunden einzutragen.'}/>
                                                                    </label>
                                                                    <textarea
                                                                        className='form-control'
                                                                        value={item.description[0].text}
                                                                        onChange={(e) => {
                                                                            updateCustomField(item.customField, {description: [{text: e.target.value, locale: 'de'}]})
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>)}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                                {errors.kurtaxe && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.kurtaxe}</div></div>}
                                {errors.kurtaxeHidden && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.kurtaxeHidden}</div></div>}
                                {errors.carHidden && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.carHidden}</div></div>}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeModal}>
                                Abbrechen
                            </span>
                            <span className='btn btn-success' onClick={submitModal}>
                                Speichern
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddServiceModal;
